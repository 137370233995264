import React, { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const TOKENS = [
  { symbol: 'BTC', name: 'Bitcoin', image: '/tokens-icons/btc.png', balance: 0.5 },
  { symbol: 'ETH', name: 'Ethereum', image: '/tokens-icons/eth.png', balance: 2.5 },
  { symbol: 'BNB', name: 'Binance Coin', image: '/tokens-icons/bnb.png', balance: 10.0 },
  { symbol: 'USDT', name: 'Tether', image: '/tokens-icons/usdt.png', balance: 5000.00 },
  { symbol: 'USDC', name: 'USD Coin', image: '/tokens-icons/usdc.png', balance: 5000.00 },
  { symbol: 'DAI', name: 'Dai', image: '/tokens-icons/dai.png', balance: 5000.00 },
  { symbol: 'KAS', name: 'Kaspa', image: '/tokens-icons/kas.svg', balance: 1500.45 },
  { symbol: 'VORDEX', name: 'Vordex', image: '/tokens-icons/kas.svg', balance: 2500.00 },
];

function TokenSelector({ isOpen, onClose, onSelect, selectedToken }) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredTokens = TOKENS.filter(token => 
    token.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
    token.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
          />

          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-x-0 top-[20%] mx-auto max-w-md p-4 z-50"
          >
            <div className="bg-gray-800 rounded-2xl shadow-xl">
              {/* Header */}
              <div className="flex justify-between items-center p-4 border-b border-gray-700">
                <h2 className="text-xl font-bold text-white">Select Token</h2>
                <button 
                  onClick={onClose}
                  className="text-gray-400 hover:text-white"
                >
                  <FaTimes size={20} />
                </button>
              </div>

              {/* Search */}
              <div className="p-4">
                <div className="relative">
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search by name or paste address"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full bg-gray-700 rounded-xl py-2 pl-10 pr-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-400"
                  />
                </div>
              </div>

              {/* Token List */}
              <div className="max-h-[300px] overflow-y-auto">
                {filteredTokens.map(token => (
                  <button
                    key={token.symbol}
                    onClick={() => {
                      onSelect(token);
                      onClose();
                    }}
                    className={`w-full flex items-center justify-between p-4 hover:bg-gray-700 transition-colors ${
                      selectedToken?.symbol === token.symbol ? 'bg-gray-700' : ''
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      <img src={token.image} alt={token.symbol} className="w-8 h-8 rounded-full" />
                      <div className="text-left">
                        <div className="text-white font-semibold">{token.symbol}</div>
                        <div className="text-gray-400 text-sm">{token.name}</div>
                      </div>
                    </div>
                    <div className="text-right text-gray-400">
                      {token.balance.toFixed(4)}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default TokenSelector; 
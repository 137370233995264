import React from 'react';
import { Link } from 'react-router-dom';
import { FaLock, FaChartLine, FaVoteYea, FaBolt, FaArrowRight } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

function FeatureCard({ icon, title, description }) {
  return (
    <motion.div 
      whileHover={{ scale: 1.02 }}
      className="p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 hover:border-emerald-400/50 transition-all duration-300 group"
    >
      <div className="text-emerald-400 text-3xl mb-6 group-hover:scale-110 transition-transform duration-300">
        {icon}
      </div>
      <h3 className="text-xl font-bold mb-3 text-white group-hover:text-emerald-400 transition-colors">{title}</h3>
      <p className="text-gray-400 leading-relaxed">{description}</p>
    </motion.div>
  );
}

function StatsCard({ value, label }) {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 hover:border-emerald-400/50 transition-all duration-300"
    >
      <div className="text-4xl font-bold bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent mb-3">{value}</div>
      <div className="text-gray-400">{label}</div>
    </motion.div>
  );
}

function RoadmapCard({ quarter, items }) {
  return (
    <motion.div 
      whileHover={{ scale: 1.02 }}
      className="p-8 rounded-2xl bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 hover:border-emerald-400/50 transition-all duration-300"
    >
      <h3 className="text-xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent">{quarter}</h3>
      <ul className="space-y-4">
        {items.map((item, index) => (
          <li key={index} className="flex items-start gap-3">
            <span className="text-emerald-400 mt-1.5">•</span>
            <span className="text-gray-300 hover:text-white transition-colors">{item}</span>
          </li>
        ))}
      </ul>
    </motion.div>
  );
}

function Landing() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex flex-col text-white">
      <Navbar />

      {/* Hero Section */}
      <section className="pt-32 pb-20 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(16,185,129,0.1)_0%,transparent_70%)]" />
        <div className="container mx-auto px-6">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto text-center"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="inline-block mb-6 px-6 py-2 rounded-full bg-emerald-400/10 text-emerald-400 text-sm font-semibold"
            >
              Welcome to the Future of DeFi
            </motion.div>
            <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
              The Next Generation
              <span className="block bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent">
                Vote-Escrow DEX
              </span>
            </h1>
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-xl text-gray-400 mb-10 leading-relaxed"
            >
              Trade, stake, and shape the future of DeFi. Earn rewards while having a voice in protocol governance.
            </motion.p>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
              className="flex gap-4 justify-center"
            >
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link 
                  to="/swap"
                  className="group flex items-center gap-2 bg-gradient-to-r from-emerald-400 to-emerald-500 text-gray-900 px-8 py-4 rounded-xl font-bold hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
                >
                  Start Trading
                  <FaArrowRight className="group-hover:translate-x-1 transition-transform" />
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <a 
                  href="/"
                  className="flex items-center gap-2 border border-emerald-400/50 text-emerald-400 px-8 py-4 rounded-xl font-bold hover:bg-emerald-400/10 transition-all duration-200"
                >
                  Read Docs
                </a>
              </motion.div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-800/50 to-transparent" />
        <div className="container mx-auto px-6 relative">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            <StatsCard value="$5.2M" label="Total Value Locked" />
            <StatsCard value="320K" label="Total Trades" />
            <StatsCard value="50K" label="Active Users" />
            <StatsCard value="12" label="Governance Proposals" />
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent">
                Why Choose Vordex?
              </span>
            </h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Experience the next generation of decentralized trading with our unique vote-escrow model
            </p>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard 
              icon={<FaLock />}
              title="Secure Trading"
              description="Enterprise-grade security with advanced encryption and multi-sig protection for your assets."
            />
            <FeatureCard 
              icon={<FaVoteYea />}
              title="Vote-Escrow Model"
              description="Lock tokens to earn voting power and actively participate in shaping protocol decisions."
            />
            <FeatureCard 
              icon={<FaBolt />}
              title="Lightning Fast"
              description="Experience near-instant trades with our optimized blockchain infrastructure."
            />
            <FeatureCard 
              icon={<FaChartLine />}
              title="Earn Rewards"
              description="Maximize your returns through liquidity provision and active governance participation."
            />
          </div>
        </div>
      </section>

      {/* Roadmap Section */}
      <section id="roadmap" className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-800/50 to-transparent" />
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-6">
              <span className="bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent">
                Roadmap
              </span>
            </h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Our journey to revolutionize decentralized trading
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <RoadmapCard 
              quarter="Q1 2025"
              items={[
                "Platform Launch",
                "Basic Swap Functionality",
                "Token Launch"
              ]}
            />
            <RoadmapCard 
              quarter="Q2 2025"
              items={[
                "Vote-Escrow Implementation",
                "Governance Portal",
                "Advanced Trading Features"
              ]}
            />
            <RoadmapCard 
              quarter="Q3 2025"
              items={[
                "Analytics Dashboard",
                "Mobile App Beta",
                "Cross-chain Integration"
              ]}
            />
            <RoadmapCard 
              quarter="Q4 2025"
              items={[
                "Advanced Governance",
                "Mobile App Launch",
                "Protocol Expansion"
              ]}
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(16,185,129,0.1)_0%,transparent_70%)]" />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="container mx-auto px-6 text-center relative"
        >
          <h2 className="text-4xl font-bold mb-6">
            <span className="bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent">
              Ready to Start Trading?
            </span>
          </h2>
          <p className="text-gray-400 text-lg mb-10 max-w-2xl mx-auto">
            Join thousands of traders in the next generation of decentralized finance.
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link 
              to="/swap"
              className="group inline-flex items-center gap-2 bg-gradient-to-r from-emerald-400 to-emerald-500 text-gray-900 px-8 py-4 rounded-xl font-bold hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
            >
              Launch App
              <FaArrowRight className="group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </motion.div>
      </section>

      <Footer />
    </div>
  );
}

export default Landing; 
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);

const generateDailyData = (days) => {
  return Array.from({ length: days }, (_, i) => ({
    date: new Date(Date.now() - (days - i - 1) * 24 * 60 * 60 * 1000).toLocaleDateString(),
    volume: Math.random() * 1000000 + 500000,
    tvl: Math.random() * 5000000 + 2000000,
    fees: Math.random() * 10000 + 5000,
  }));
};

const dailyData = generateDailyData(30);

const tokenDistribution = {
  labels: ['VORDEX/USDT', 'VORDEX/BTC', 'VORDEX/ETH', 'VORDEX/USDC', 'VORDEX/DAI'],
  datasets: [{
    data: [40, 25, 15, 12, 8],
    backgroundColor: [
      '#34d399',
      '#60a5fa',
      '#f87171',
      '#fbbf24',
      '#a78bfa'
    ],
    borderWidth: 0
  }]
};

function Analytics() {
  const [timeframe, setTimeframe] = useState('30d');
  const [selectedMetric, setSelectedMetric] = useState('all');

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#fff',
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#fff'
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#fff'
        }
      }
    }
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          color: '#fff',
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.label}: ${context.parsed}%`;
          }
        }
      }
    }
  };

  const combinedData = {
    labels: dailyData.map(d => d.date),
    datasets: [
      ...(selectedMetric === 'all' || selectedMetric === 'volume' ? [{
        label: 'Volume',
        data: dailyData.map(d => d.volume),
        borderColor: '#34d399',
        backgroundColor: 'rgba(52, 211, 153, 0.1)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y'
      }] : []),
      ...(selectedMetric === 'all' || selectedMetric === 'tvl' ? [{
        label: 'TVL',
        data: dailyData.map(d => d.tvl),
        borderColor: '#60a5fa',
        backgroundColor: 'rgba(96, 165, 250, 0.1)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y'
      }] : []),
      ...(selectedMetric === 'all' || selectedMetric === 'fees' ? [{
        label: 'Fees',
        data: dailyData.map(d => d.fees),
        borderColor: '#f87171',
        backgroundColor: 'rgba(248, 113, 113, 0.1)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y'
      }] : [])
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        className="flex-grow container mx-auto px-6 pt-24 pb-12 max-w-7xl"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="w-full">
          {/* Header */}
          <div className="flex justify-between items-center mb-10">
            <h1 className="text-3xl font-bold text-white tracking-tight">Analytics</h1>
            <div className="flex gap-2 bg-gray-800/50 p-1.5 rounded-2xl">
              {['24h', '7d', '30d', 'All'].map((period) => (
                <button
                  key={period}
                  onClick={() => setTimeframe(period)}
                  className={`px-6 py-2.5 rounded-xl font-semibold transition-all duration-300 ${
                    timeframe === period
                      ? 'bg-emerald-400 text-gray-900 shadow-lg'
                      : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
                  }`}
                >
                  {period}
                </button>
              ))}
            </div>
          </div>

          {/* Overview Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
            <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
              <h3 className="text-gray-400 text-sm mb-2">Total Volume (24h)</h3>
              <p className="text-2xl font-bold text-white">$1.25M</p>
              <div className="flex items-center gap-2 mt-2">
                <span className="text-emerald-400 flex items-center gap-1">
                  <FaArrowUp className="text-sm" />
                  12.5%
                </span>
                <span className="text-gray-500 text-sm">vs last 24h</span>
              </div>
            </div>
            <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
              <h3 className="text-gray-400 text-sm mb-2">TVL</h3>
              <p className="text-2xl font-bold text-white">$4.8M</p>
              <div className="flex items-center gap-2 mt-2">
                <span className="text-red-400 flex items-center gap-1">
                  <FaArrowDown className="text-sm" />
                  2.3%
                </span>
                <span className="text-gray-500 text-sm">vs last 24h</span>
              </div>
            </div>
            <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
              <h3 className="text-gray-400 text-sm mb-2">Fees (24h)</h3>
              <p className="text-2xl font-bold text-white">$8.5K</p>
              <div className="flex items-center gap-2 mt-2">
                <span className="text-emerald-400 flex items-center gap-1">
                  <FaArrowUp className="text-sm" />
                  5.7%
                </span>
                <span className="text-gray-500 text-sm">vs last 24h</span>
              </div>
            </div>
          </div>

          {/* Charts Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
            {/* Combined Chart */}
            <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 md:col-span-2 border border-gray-700/50">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-white font-semibold text-lg">Overview</h3>
                <div className="flex gap-2 bg-gray-700/50 p-1.5 rounded-xl">
                  {['all', 'volume', 'tvl', 'fees'].map((metric) => (
                    <button
                      key={metric}
                      onClick={() => setSelectedMetric(metric)}
                      className={`px-4 py-1.5 rounded-lg font-semibold text-sm transition-all duration-300 ${
                        selectedMetric === metric
                          ? 'bg-emerald-400 text-gray-900'
                          : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
                      }`}
                    >
                      {metric.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
              <div className="h-[400px]">
                <Line options={chartOptions} data={combinedData} />
              </div>
            </div>

            {/* Pie Chart */}
            <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
              <h3 className="text-white font-semibold text-lg mb-6">TVL Distribution</h3>
              <div className="h-[400px] flex items-center">
                <Pie options={pieOptions} data={tokenDistribution} />
              </div>
            </div>
          </div>

          {/* Top Pools Table */}
          <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
            <h3 className="text-white font-semibold text-lg mb-6">Top Pools</h3>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-gray-400 text-sm border-b border-gray-700">
                    <th className="text-left py-4 px-4">Pool</th>
                    <th className="text-right py-4 px-4">TVL</th>
                    <th className="text-right py-4 px-4">Volume (24h)</th>
                    <th className="text-right py-4 px-4">Fees (24h)</th>
                    <th className="text-right py-4 px-4">APR</th>
                  </tr>
                </thead>
                <tbody className="text-white">
                  {[1, 2, 3, 4, 5].map((i) => (
                    <tr key={i} className="border-b border-gray-700/50 hover:bg-gray-700/30 transition-colors">
                      <td className="py-4 px-4">
                        <div className="flex items-center gap-3">
                          <div className="flex -space-x-2">
                            <img src="/tokens/btc.png" alt="BTC" className="w-8 h-8 rounded-full ring-2 ring-gray-800" />
                            <img src="/tokens/usdt.png" alt="USDT" className="w-8 h-8 rounded-full ring-2 ring-gray-800" />
                          </div>
                          <span>BTC/USDT</span>
                        </div>
                      </td>
                      <td className="text-right py-4 px-4">$2.5M</td>
                      <td className="text-right py-4 px-4">$450K</td>
                      <td className="text-right py-4 px-4">$1.35K</td>
                      <td className="text-right py-4 px-4">
                        <span className="text-emerald-400">24.5%</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </motion.div>

      <Footer />
    </div>
  );
}

export default Analytics; 
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const navLinksVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        staggerChildren: 0.1
      }
    },
    exit: { opacity: 0, y: 20 }
  };

  const linkVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
  };

  return (
    <nav className="fixed w-full bg-gray-900/95 backdrop-blur-sm z-50 py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <span className="text-2xl font-bold text-emerald-400">VORDEX</span>
        </Link>
        <div className="flex gap-6 items-center">
          <AnimatePresence mode="wait">
            {!isHomePage && (
              <motion.div 
                className="flex gap-6 items-center"
                variants={navLinksVariants}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <motion.div variants={linkVariants}>
                  <Link to="/swap" className="text-white hover:text-emerald-400">Swap</Link>
                </motion.div>
                <motion.div variants={linkVariants}>
                  <Link to="/pool" className="text-white hover:text-emerald-400">Pool</Link>
                </motion.div>
                <motion.div variants={linkVariants}>
                  <Link to="/analytics" className="text-white hover:text-emerald-400">Analytics</Link>
                </motion.div>
                <motion.div variants={linkVariants}>
                  <Link to="/dashboard" className="text-white hover:text-emerald-400">Dashboard</Link>
                </motion.div>
                <motion.div variants={linkVariants}>
                  <a href="/" className="text-white hover:text-emerald-400">Docs</a>
                </motion.div>
              </motion.div>
            )}
            {isHomePage && (
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
              >
                <Link 
                  to="/swap"
                  className="bg-emerald-400 text-gray-900 px-4 py-2 rounded-lg hover:bg-emerald-500 transition-colors"
                >
                  Launch App
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 
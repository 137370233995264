import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const navLinksVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        staggerChildren: 0.1
      }
    },
    exit: { opacity: 0, y: 20 }
  };

  const linkVariants = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
  };

  return (
    <nav className="fixed w-full bg-gray-900/80 backdrop-blur-md border-b border-gray-700/50 z-50">
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex items-center group">
            <span className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent group-hover:to-emerald-400 transition-all duration-300">VORDEX</span>
          </Link>
          
          <div className="flex gap-6 items-center">
            <AnimatePresence mode="wait">
              {!isHomePage && (
                <motion.div 
                  className="flex gap-2 items-center bg-gray-800/50 rounded-2xl p-1.5"
                  variants={navLinksVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  {['Swap', 'Pool', 'Analytics', 'Dashboard', 'Docs'].map((item) => (
                    <motion.div key={item} variants={linkVariants}>
                      <Link
                        to={item === 'Docs' ? '/' : `/${item.toLowerCase()}`}
                        className={`px-4 py-2 rounded-xl font-medium transition-all duration-200 ${
                          location.pathname === `/${item.toLowerCase()}`
                            ? 'bg-emerald-400 text-gray-900'
                            : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
                        }`}
                      >
                        {item}
                      </Link>
                    </motion.div>
                  ))}
                </motion.div>
              )}
              {isHomePage && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                >
                  <Link 
                    to="/swap"
                    className="bg-gradient-to-r from-emerald-400 to-emerald-500 text-gray-900 px-6 py-2.5 rounded-xl font-bold hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
                  >
                    Launch App
                  </Link>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 
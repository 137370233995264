import React, { useState } from 'react';
import { FaChevronLeft, FaChevronDown, FaInfoCircle, FaPlus, FaExchangeAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TokenSelector from '../components/TokenSelector';
import { motion, AnimatePresence } from 'framer-motion';

const TOKENS = [
  { symbol: 'BTC', name: 'Bitcoin', image: '/tokens-icons/btc.png', balance: 0.5 },
  { symbol: 'ETH', name: 'Ethereum', image: '/tokens-icons/eth.png', balance: 2.5 },
  { symbol: 'BNB', name: 'Binance Coin', image: '/tokens-icons/bnb.png', balance: 10.0 },
  { symbol: 'USDT', name: 'Tether', image: '/tokens-icons/usdt.png', balance: 5000.00 },
  { symbol: 'USDC', name: 'USD Coin', image: '/tokens-icons/usdc.png', balance: 5000.00 },
  { symbol: 'DAI', name: 'Dai', image: '/tokens-icons/dai.png', balance: 5000.00 },
  { symbol: 'VORDEX', name: 'Vordex', image: '/tokens-icons/btc.png', balance: 2500.00 },
];

function AddLiquidity() {
  const [token0, setToken0] = useState(TOKENS[0]);
  const [token1, setToken1] = useState(TOKENS[1]);
  const [amount0, setAmount0] = useState('');
  const [amount1, setAmount1] = useState('');
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [activeField, setActiveField] = useState(null);

  const handleOpenSelector = (field) => {
    setActiveField(field);
    setSelectorOpen(true);
  };

  const handleSelectToken = (token) => {
    if (activeField === 'token0') {
      if (token.symbol === token1.symbol) {
        setToken1(token0);
      }
      setToken0(token);
    } else {
      if (token.symbol === token0.symbol) {
        setToken0(token1);
      }
      setToken1(token);
    }
    setSelectorOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex-grow container mx-auto px-6 pt-24 pb-12 max-w-2xl"
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-4">
            <Link 
              to="/pool"
              className="p-2 hover:bg-gray-800/50 rounded-xl transition-colors"
            >
              <FaChevronLeft className="text-gray-400 hover:text-white" />
            </Link>
            <h1 className="text-2xl font-bold text-white">Add Liquidity</h1>
          </div>
          <button className="flex items-center gap-2 text-gray-400 hover:text-white bg-gray-800/50 px-4 py-2 rounded-xl hover:bg-gray-700/50 transition-all">
            <FaInfoCircle size={16} />
            Help
          </button>
        </div>

        <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
          {/* Token 0 Input */}
          <div className="bg-gray-700/50 rounded-xl p-4 mb-2">
            <div className="flex justify-between mb-2">
              <label className="text-sm text-gray-400">Input</label>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">Balance: {token0.balance}</span>
                <button 
                  onClick={() => setAmount0(token0.balance)}
                  className="text-xs text-emerald-400 hover:text-emerald-300 font-medium"
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                value={amount0}
                onChange={(e) => setAmount0(e.target.value)}
                placeholder="0.0"
                className="flex-1 bg-gray-800/50 rounded-xl px-4 py-3 text-white text-lg font-medium placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500/50"
              />
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleOpenSelector('token0')}
                className="flex items-center gap-2 bg-gray-800/80 hover:bg-gray-800 rounded-xl px-4 py-2 transition-colors group"
              >
                <div className="flex items-center gap-2">
                  <img src={token0.image} alt={token0.symbol} className="w-6 h-6 rounded-full ring-2 ring-gray-700" />
                  <span className="text-white font-medium">{token0.symbol}</span>
                </div>
                <FaChevronDown className="text-gray-400 group-hover:text-white transition-colors" />
              </motion.button>
            </div>
          </div>

          {/* Plus Icon */}
          <div className="flex justify-center -my-3 relative z-10">
            <motion.div 
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
              className="bg-gray-800 p-3 rounded-xl text-emerald-400 border border-gray-700"
            >
              <FaPlus />
            </motion.div>
          </div>

          {/* Token 1 Input */}
          <div className="bg-gray-700/50 rounded-xl p-4 mt-2">
            <div className="flex justify-between mb-2">
              <label className="text-sm text-gray-400">Input</label>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">Balance: {token1.balance}</span>
                <button 
                  onClick={() => setAmount1(token1.balance)}
                  className="text-xs text-emerald-400 hover:text-emerald-300 font-medium"
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="flex gap-3">
              <input
                type="number"
                value={amount1}
                onChange={(e) => setAmount1(e.target.value)}
                placeholder="0.0"
                className="flex-1 bg-gray-800/50 rounded-xl px-4 py-3 text-white text-lg font-medium placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500/50"
              />
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleOpenSelector('token1')}
                className="flex items-center gap-2 bg-gray-800/80 hover:bg-gray-800 rounded-xl px-4 py-2 transition-colors group"
              >
                <div className="flex items-center gap-2">
                  <img src={token1.image} alt={token1.symbol} className="w-6 h-6 rounded-full ring-2 ring-gray-700" />
                  <span className="text-white font-medium">{token1.symbol}</span>
                </div>
                <FaChevronDown className="text-gray-400 group-hover:text-white transition-colors" />
              </motion.button>
            </div>
          </div>

          {/* Pool Information */}
          <div className="mt-6 p-4 bg-gray-700/30 rounded-xl space-y-3">
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">Pool Share</span>
              <span className="text-white font-medium">0.3%</span>
            </div>
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">Exchange Rate</span>
              <span className="text-white font-medium">1 {token0.symbol} = 1.5 {token1.symbol}</span>
            </div>
          </div>

          {/* Add Liquidity Button */}
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="w-full mt-6 bg-gradient-to-r from-emerald-400 to-emerald-500 text-gray-900 font-bold py-4 px-6 rounded-xl hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
          >
            Add Liquidity
          </motion.button>
        </div>
      </motion.div>

      <AnimatePresence>
        {selectorOpen && (
          <TokenSelector
            isOpen={selectorOpen}
            onClose={() => setSelectorOpen(false)}
            onSelect={handleSelectToken}
            selectedToken={activeField === 'token0' ? token0 : token1}
          />
        )}
      </AnimatePresence>

      <Footer />
    </div>
  );
}

export default AddLiquidity; 
import React from 'react';
import { FaDiscord, FaTwitter, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="py-12 bg-gray-800/30">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          <Link to="/" className="text-2xl font-bold text-emerald-400">VORDEX</Link>
          <div className="flex gap-6">
            <a href="/" className="text-gray-400 hover:text-emerald-400">
              <FaDiscord size={24} />
            </a>
            <a href="/" className="text-gray-400 hover:text-emerald-400">
              <FaTwitter size={24} />
            </a>
            <a href="/" className="text-gray-400 hover:text-emerald-400">
              <FaGithub size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 
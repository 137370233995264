import React from 'react';
import { motion } from 'framer-motion';
import { FaWallet, FaChartLine, FaExchangeAlt, FaHistory, FaArrowUp, FaArrowDown, FaSort, FaFilter, FaPlus } from 'react-icons/fa';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Enregistrer les composants Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function AssetCard({ asset }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.01 }}
      className="bg-gray-700/50 backdrop-blur-sm rounded-xl p-6 border border-gray-600/50 hover:border-emerald-500/30 transition-all duration-300"
    >
      <div className="flex items-center gap-4">
        <img src={asset.icon} alt={asset.name} className="w-10 h-10 rounded-full ring-2 ring-gray-700" />
        <div className="flex-grow">
          <h3 className="text-lg font-semibold text-white">{asset.name}</h3>
          <p className="text-gray-400">{asset.symbol}</p>
        </div>
        <div className="text-right mr-6">
          <div className="flex flex-col items-end">
            <p className="text-lg font-semibold text-white">${asset.value}</p>
            <p className="text-sm text-gray-400">{asset.quantity} {asset.symbol}</p>
            <p className={`text-sm ${asset.change >= 0 ? 'text-emerald-400' : 'text-red-400'}`}>
              {asset.change >= 0 ? '+' : ''}{asset.change}%
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-4 py-2 bg-emerald-400 text-gray-900 rounded-xl text-sm font-semibold hover:bg-emerald-500 transition-colors"
          >
            Swap
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="px-4 py-2 border border-emerald-400 text-emerald-400 rounded-xl text-sm font-semibold hover:bg-emerald-400/10 transition-all"
          >
            Add
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
}

function StatCard({ icon, title, value, trend, isPositive }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50 hover:border-emerald-500/30 transition-all duration-300 group"
    >
      <div className="flex items-center gap-4">
        <div className="p-3 bg-emerald-400/10 rounded-xl text-emerald-400 group-hover:bg-emerald-400 group-hover:text-gray-900 transition-all duration-300">
          {icon}
        </div>
        <div className="flex-grow">
          <p className="text-gray-400 text-sm">{title}</p>
          <p className="text-xl font-bold text-white mt-1 group-hover:text-emerald-400 transition-colors">{value}</p>
          <p className={`text-sm mt-1 flex items-center gap-1 ${isPositive ? 'text-emerald-400' : 'text-red-400'}`}>
            {isPositive ? <FaArrowUp size={12} /> : <FaArrowDown size={12} />}
            {trend}
          </p>
        </div>
      </div>
    </motion.div>
  );
}

function Dashboard() {
  // Mock data avec quantités ajoutées
  const assets = [
    { 
      name: 'Vordex', 
      symbol: 'VORDEX', 
      value: '1,234.56', 
      quantity: '12,345.67',
      change: 5.67, 
      icon: '/tokens-icons/btc.png' 
    },
    { 
      name: 'Ethereum', 
      symbol: 'ETH', 
      value: '2,345.67', 
      quantity: '1.234',
      change: -2.34, 
      icon: '/tokens-icons/eth.png' 
    },
    { 
      name: 'Bitcoin', 
      symbol: 'BTC', 
      value: '45,678.90', 
      quantity: '0.567',
      change: 1.23, 
      icon: '/tokens-icons/btc.png' 
    },
    // Ajouter plus d'assets selon besoin
  ];

  const recentTransactions = [
    { type: 'Swap', from: 'VORDEX', to: 'ETH', amount: '100', date: '2024-03-20' },
    { type: 'Add Liquidity', token0: 'VORDEX', token1: 'ETH', amount: '500', date: '2024-03-19' },
    // Ajouter plus de transactions selon besoin
  ];

  // Mock data pour les graphiques
  const portfolioHistory = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Portfolio Value',
        data: [35000, 42000, 39000, 45000, 48000, 49258],
        borderColor: '#10B981',
        backgroundColor: 'rgba(16, 185, 129, 0.1)',
        fill: true,
        tension: 0.4
      }
    ]
  };

  const distributionData = {
    labels: ['VORDEX', 'ETH', 'BTC'],
    datasets: [{
      data: [1234.56, 2345.67, 45678.90],
      backgroundColor: [
        '#10B981',
        '#6366F1',
        '#F59E0B'
      ],
      borderWidth: 0
    }]
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: '#1F2937',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        borderColor: '#374151',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            return `$${context.parsed.y.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF'
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#9CA3AF',
          callback: (value) => `$${value.toLocaleString()}`
        }
      }
    }
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: '#9CA3AF',
          usePointStyle: true,
          padding: 20
        }
      },
      tooltip: {
        backgroundColor: '#1F2937',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        callbacks: {
          label: function(context) {
            return `$${context.parsed.toLocaleString()}`;
          }
        }
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex flex-col">
      <Navbar />
      
      <main className="flex-grow container mx-auto px-6 pt-24 pb-12 max-w-7xl">
        {/* Welcome Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-10"
        >
          <h1 className="text-4xl font-bold text-white mb-2">Welcome back 👋</h1>
          <p className="text-gray-400">Here&apos;s what&apos;s happening with your portfolio today.</p>
        </motion.div>

        {/* Stats Overview */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10"
        >
          <StatCard 
            icon={<FaWallet size={24} />}
            title="Total Balance"
            value="$49,258.13"
            trend="+12.5%"
            isPositive={true}
          />
          <StatCard 
            icon={<FaChartLine size={24} />}
            title="24h Change"
            value="+$1,234.56"
            trend="+8.3%"
            isPositive={true}
          />
          <StatCard 
            icon={<FaExchangeAlt size={24} />}
            title="Total Trades"
            value="123"
            trend="+5 today"
            isPositive={true}
          />
          <StatCard 
            icon={<FaHistory size={24} />}
            title="Active Positions"
            value="5"
            trend="+2 this week"
            isPositive={true}
          />
        </motion.div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-10">
          {/* Portfolio Value Chart */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-8 border border-gray-700/50 hover:border-emerald-500/30 transition-all duration-300"
          >
            <div className="flex justify-between items-center mb-8">
              <div>
                <h2 className="text-xl font-bold text-white">Portfolio Value</h2>
                <p className="text-gray-400 text-sm mt-1">Last 30 days</p>
              </div>
              <div className="flex items-center gap-2 bg-gray-700/50 rounded-xl p-1">
                {['1D', '1W', '1M', '1Y'].map((period) => (
                  <button
                    key={period}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ${
                      period === '1M'
                        ? 'bg-emerald-400 text-gray-900'
                        : 'text-gray-400 hover:text-white'
                    }`}
                  >
                    {period}
                  </button>
                ))}
              </div>
            </div>
            <div className="h-[300px]">
              <Line options={lineOptions} data={portfolioHistory} />
            </div>
          </motion.div>

          {/* Portfolio Distribution */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-8 border border-gray-700/50 hover:border-emerald-500/30 transition-all duration-300"
          >
            <div className="flex justify-between items-center mb-8">
              <div>
                <h2 className="text-xl font-bold text-white">Portfolio Distribution</h2>
                <p className="text-gray-400 text-sm mt-1">Asset allocation</p>
              </div>
              <button className="text-emerald-400 hover:text-emerald-300 text-sm font-medium transition-colors">
                See Details
              </button>
            </div>
            <div className="h-[300px] flex items-center justify-center">
              <Doughnut options={doughnutOptions} data={distributionData} />
            </div>
          </motion.div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Portfolio Section */}
          <div className="lg:col-span-2">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-8 border border-gray-700/50"
            >
              <div className="flex justify-between items-center mb-8">
                <div>
                  <h2 className="text-xl font-bold text-white">Your Portfolio</h2>
                  <p className="text-gray-400 text-sm mt-1">Manage your assets</p>
                </div>
                <div className="flex gap-3">
                  <button className="px-4 py-2.5 text-sm bg-gray-700/50 text-gray-400 rounded-xl hover:bg-gray-700 hover:text-white transition-all flex items-center gap-2">
                    <FaSort size={14} />
                    Sort
                  </button>
                  <button className="px-4 py-2.5 text-sm bg-gray-700/50 text-gray-400 rounded-xl hover:bg-gray-700 hover:text-white transition-all flex items-center gap-2">
                    <FaFilter size={14} />
                    Filter
                  </button>
                </div>
              </div>
              <div className="space-y-4">
                {assets.map((asset) => (
                  <AssetCard 
                    key={asset.symbol} 
                    asset={asset}
                  />
                ))}
              </div>
            </motion.div>
          </div>

          {/* Recent Transactions */}
          <div className="lg:col-span-1">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-8 border border-gray-700/50"
            >
              <div className="flex justify-between items-center mb-8">
                <div>
                  <h2 className="text-xl font-bold text-white">Recent Activity</h2>
                  <p className="text-gray-400 text-sm mt-1">Your latest transactions</p>
                </div>
                <button className="text-emerald-400 hover:text-emerald-300 text-sm font-medium transition-colors">
                  View All
                </button>
              </div>
              <div className="space-y-4">
                {recentTransactions.map((tx, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    className="group bg-gray-700/50 backdrop-blur-sm rounded-xl p-4 border border-gray-600/50 hover:border-emerald-500/30 hover:bg-gray-700/70 transition-all duration-300"
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div className="flex items-center gap-3">
                          <div className={`w-10 h-10 rounded-xl flex items-center justify-center ${
                            tx.type === 'Swap' ? 'bg-emerald-400/10' : 'bg-blue-400/10'
                          }`}>
                            {tx.type === 'Swap' ? (
                              <FaExchangeAlt className="text-emerald-400" />
                            ) : (
                              <FaPlus className="text-blue-400" />
                            )}
                          </div>
                          <div>
                            <p className="text-white font-semibold group-hover:text-emerald-400 transition-colors">{tx.type}</p>
                            <p className="text-sm text-gray-400">
                              {tx.type === 'Swap' 
                                ? `${tx.from} → ${tx.to}`
                                : `${tx.token0} + ${tx.token1}`
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="text-white font-medium">${tx.amount}</p>
                        <p className="text-sm text-gray-400">{tx.date}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default Dashboard; 
import React, { useState } from 'react';
import { FaArrowDown, FaCog, FaChevronDown, FaTimes, FaExchangeAlt } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TokenSelector from '../components/TokenSelector';
import { motion, AnimatePresence } from 'framer-motion';

const TOKENS = [
  { symbol: 'BTC', name: 'Bitcoin', image: '/tokens-icons/btc.png', balance: 0.5 },
  { symbol: 'ETH', name: 'Ethereum', image: '/tokens-icons/eth.png', balance: 2.5 },
  { symbol: 'BNB', name: 'Binance Coin', image: '/tokens-icons/bnb.png', balance: 10.0 },
  { symbol: 'USDT', name: 'Tether', image: '/tokens-icons/usdt.png', balance: 5000.00 },
  { symbol: 'USDC', name: 'USD Coin', image: '/tokens-icons/usdc.png', balance: 5000.00 },
  { symbol: 'DAI', name: 'Dai', image: '/tokens-icons/dai.png', balance: 5000.00 },
  { symbol: 'VORDEX', name: 'Vordex', image: '/tokens-icons/btc.png', balance: 2500.00 },
];

// Moved price changes calculation outside the component
const tokensWithPriceChanges = TOKENS.map(token => ({
  ...token,
  priceChange: (Math.random() * 20 - 10).toFixed(2)
}));

// Generate random positions once for each token
const tokensWithPositions = tokensWithPriceChanges.map(token => ({
  ...token,
  position: {
    x: Math.random() * 100,
    y: Math.random() * 100,
    duration: 20 + Math.random() * 10
  }
}));

function SettingsModal({ isOpen, onClose, slippage, setSlippage }) {
  const [rpcUrl, setRpcUrl] = useState('');
  const [deadline, setDeadline] = useState(5);
  const predefinedSlippages = [0.01, 0.1, 0.5, 1, 5];

  const handleSlippageChange = (value) => {
    if (value >= 0 && value <= 25) {
      setSlippage(value);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/80 flex items-center justify-center z-50"
        >
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-gray-900 rounded-2xl p-6 w-full max-w-md mx-4"
          >
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-2">
                <span className="text-emerald-400 text-lg">Settings</span>
              </div>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-300">
                <FaTimes />
              </button>
            </div>

            {/* RPC URL Section */}
            <div className="mb-8">
              <p className="text-gray-400 text-sm mb-2">
                To communicate with the network with your personal PC, add it here and reload the page.
                This is saved only in your browser, locally.
              </p>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={rpcUrl}
                  onChange={(e) => setRpcUrl(e.target.value)}
                  placeholder="RPC URL ..."
                  className="flex-1 bg-gray-800 rounded-lg px-4 py-2 text-white"
                />
              </div>
              <p className="text-gray-500 text-sm mt-2">
                Leave blank if you want to use the PC provided by us.
              </p>
            </div>

            {/* Max Slippage Section */}
            <div className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-white flex items-center gap-2">
                  <span>Max Slippage</span>
                </h3>
                <span className="text-white">{slippage}%</span>
              </div>
              
              <div className="flex gap-2 mb-4">
                {predefinedSlippages.map((value) => (
                  <button
                    key={value}
                    onClick={() => handleSlippageChange(value)}
                    className={`px-4 py-2 rounded-lg ${
                      slippage === value
                        ? 'bg-emerald-400 text-white'
                        : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
                    }`}
                  >
                    {value}%
                  </button>
                ))}
                <div className="relative flex-1">
                  <input
                    type="number"
                    value={slippage}
                    onChange={(e) => handleSlippageChange(parseFloat(e.target.value))}
                    className="w-full bg-gray-800 rounded-lg px-4 py-2 text-white"
                    placeholder="Custom"
                  />
                </div>
              </div>
              
              <p className="text-gray-500 text-sm">
                Your transaction will revert if the settled price deviates by more than this percentage.
              </p>
            </div>

            {/* Deadline Section */}
            <div className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-white">Deadline</h3>
                <span className="text-white">{deadline} minutes</span>
              </div>
              
              <input
                type="range"
                min="1"
                max="120"
                value={deadline}
                onChange={(e) => setDeadline(parseInt(e.target.value))}
                className="w-full accent-emerald-400 bg-gray-800 h-2 rounded-lg appearance-none cursor-pointer"
              />
              
              <p className="text-gray-500 text-sm mt-2">
                Your transaction will revert if it misses the deadline before a swap with acceptable slippage could be made.
              </p>
            </div>

            <button
              onClick={onClose}
              className="w-full bg-gray-800 hover:bg-gray-700 text-white font-bold py-3 px-6 rounded-xl transition-colors"
            >
              SAVE
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function TokenBackground({ token }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ 
        opacity: 0.15,
        scale: [1, 1.1, 1]
      }}
      transition={{
        opacity: { duration: 1 },
        scale: {
          repeat: Infinity,
          duration: token.position.duration,
          ease: "easeInOut"
        }
      }}
      whileHover={{ opacity: 1 }}
      className="absolute cursor-pointer hover:z-50"
      style={{
        left: `${token.position.x}%`,
        top: `${token.position.y}%`,
      }}
    >
      <div className="flex items-center gap-3 p-4 group">
        <img 
          src={token.image} 
          alt={token.name} 
          className="w-8 h-8 rounded-full shadow-[0_0_15px_rgba(16,185,129,0.3)] group-hover:shadow-[0_0_20px_rgba(16,185,129,0.5)]"
        />
        <div className="opacity-0 group-hover:opacity-100 bg-gray-800/90 absolute left-full top-1/2 -translate-y-1/2 p-2 rounded-lg ml-2 whitespace-nowrap">
          <p className="text-white font-medium">{token.symbol}</p>
          <p className={`text-sm ${token.priceChange >= 0 ? 'text-green-400' : 'text-red-400'}`}>
            {token.priceChange >= 0 ? '+' : ''}{token.priceChange}%
          </p>
        </div>
      </div>
    </motion.div>
  );
}

function Swap() {
  const [fromAmount, setFromAmount] = useState('');
  const [toAmount, setToAmount] = useState('');
  const [fromToken, setFromToken] = useState(TOKENS[0]);
  const [toToken, setToToken] = useState(TOKENS[6]);
  const [slippage, setSlippage] = useState(0.5);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [activeField, setActiveField] = useState(null); // 'from' ou 'to'

  const handleSwap = () => {
    const tempAmount = fromAmount;
    setFromAmount(toAmount);
    setToAmount(tempAmount);

    const tempCrypto = fromToken;
    setFromToken(toToken);
    setToToken(tempCrypto);
  };

  const handleOpenSelector = (field) => {
    setActiveField(field);
    setSelectorOpen(true);
  };

  const handleSelectToken = (token) => {
    if (activeField === 'from') {
      setFromToken(token);
    } else {
      setToToken(token);
    }
  };

  // Simulated conversion rates
  const conversionRates = {
    BTC: { USDT: 65000, ETH: 35, VORDEX: 650000 },
    ETH: { USDT: 3500, BTC: 0.028, VORDEX: 17500 },
    USDT: { BTC: 0.000015, ETH: 0.00028, VORDEX: 2.5 },
    VORDEX: { USDT: 0.4, BTC: 0.0000015, ETH: 0.000057 }
  };

  const calculateFees = (amount) => {
    if (!amount) return 0;
    // Calcul des frais de 0.5% (0.005)
    return amount * 0.005;
  };

  const calculateOutputAmount = (inputAmount) => {
    if (!inputAmount) return 0;
    const fees = calculateFees(inputAmount);
    // Le montant de sortie est le montant d'entrée moins les frais
    return inputAmount - fees;
  };

  const handleFromAmountChange = (e) => {
    const value = e.target.value;
    setFromAmount(value);
    setToAmount(calculateOutputAmount(value));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex-grow container mx-auto px-6 pt-24 pb-12 max-w-2xl"
      >
        <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-8 border border-gray-700/50">
          {/* Header */}
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-bold text-white">Swap</h2>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsSettingsOpen(true)}
              className="p-3 text-gray-400 hover:text-emerald-400 bg-gray-700/50 rounded-xl hover:bg-gray-700 transition-all duration-200"
            >
              <FaCog size={18} />
            </motion.button>
          </div>

          {/* From Token */}
          <div className="bg-gray-700/50 rounded-xl p-4 mb-2">
            <div className="flex justify-between mb-2">
              <label className="text-sm text-gray-400">From</label>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">Balance: {fromToken.balance}</span>
                <button 
                  onClick={() => setFromAmount(fromToken.balance)}
                  className="text-xs text-emerald-400 hover:text-emerald-300 font-medium"
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="flex gap-3">
              <AnimatePresence mode="wait">
                <motion.div
                  key={fromToken.symbol}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="flex-1"
                >
                  <input
                    type="number"
                    value={fromAmount}
                    onChange={handleFromAmountChange}
                    placeholder="0.0"
                    className="w-full bg-gray-800/50 rounded-xl px-4 py-3 text-white text-lg font-medium placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500/50"
                  />
                </motion.div>
              </AnimatePresence>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleOpenSelector('from')}
                className="flex items-center gap-2 bg-gray-800/80 hover:bg-gray-800 rounded-xl px-4 py-2 transition-colors group"
              >
                <div className="flex items-center gap-2">
                  <img src={fromToken.image} alt={fromToken.symbol} className="w-6 h-6 rounded-full ring-2 ring-gray-700" />
                  <span className="text-white font-medium">{fromToken.symbol}</span>
                </div>
                <FaChevronDown className="text-gray-400 group-hover:text-white transition-colors" />
              </motion.button>
            </div>
          </div>

          {/* Swap Icon */}
          <div className="flex justify-center -my-3 relative z-10">
            <motion.button 
              whileHover={{ scale: 1.1, rotate: 180 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleSwap}
              className="bg-gray-800 p-3 rounded-xl text-emerald-400 border border-gray-700 hover:bg-gray-700 transition-colors duration-200"
            >
              <FaExchangeAlt />
            </motion.button>
          </div>

          {/* To Token */}
          <div className="bg-gray-700/50 rounded-xl p-4 mt-2 mb-6">
            <div className="flex justify-between mb-2">
              <label className="text-sm text-gray-400">To</label>
              <span className="text-sm text-gray-400">Balance: {toToken.balance}</span>
            </div>
            <div className="flex gap-3">
              <AnimatePresence mode="wait">
                <motion.div
                  key={toToken.symbol}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="flex-1"
                >
                  <input
                    type="number"
                    value={toAmount}
                    readOnly
                    placeholder="0.0"
                    className="w-full bg-gray-800/50 rounded-xl px-4 py-3 text-white text-lg font-medium placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-emerald-500/50"
                  />
                </motion.div>
              </AnimatePresence>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleOpenSelector('to')}
                className="flex items-center gap-2 bg-gray-800/80 hover:bg-gray-800 rounded-xl px-4 py-2 transition-colors group"
              >
                <div className="flex items-center gap-2">
                  <img src={toToken.image} alt={toToken.symbol} className="w-6 h-6 rounded-full ring-2 ring-gray-700" />
                  <span className="text-white font-medium">{toToken.symbol}</span>
                </div>
                <FaChevronDown className="text-gray-400 group-hover:text-white transition-colors" />
              </motion.button>
            </div>
          </div>

          {/* Price and Info */}
          <div className="bg-gray-700/30 rounded-xl p-4 mb-6 space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Price</span>
              <div className="flex items-center gap-2">
                <span className="text-white">
                  1 {fromToken.symbol} = {conversionRates[fromToken.symbol]?.[toToken.symbol]} {toToken.symbol}
                </span>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-1.5 text-gray-400 hover:text-emerald-400 bg-gray-700/50 rounded-lg hover:bg-gray-700 transition-all duration-200"
                >
                  <FaExchangeAlt size={12} />
                </motion.button>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Slippage Tolerance</span>
              <span className="text-white">{slippage}%</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Minimum Received</span>
              <span className="text-white">
                {toAmount && (parseFloat(toAmount) * (1 - slippage / 100)).toFixed(6)} {toToken.symbol}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Network Fee</span>
              <span className="text-white">
                {fromAmount ? `${calculateFees(fromAmount)} ${fromToken.symbol}` : '-'}
              </span>
            </div>
          </div>

          {/* Connect Wallet Button */}
          <motion.button 
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="w-full bg-gradient-to-r from-emerald-400 to-emerald-500 text-gray-900 font-bold py-4 px-6 rounded-xl hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
          >
            Connect Wallet
          </motion.button>
        </div>
      </motion.div>

      <Footer />

      <AnimatePresence>
        {isSettingsOpen && (
          <SettingsModal
            isOpen={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
            slippage={slippage}
            setSlippage={setSlippage}
          />
        )}
        {selectorOpen && (
          <TokenSelector
            isOpen={selectorOpen}
            onClose={() => setSelectorOpen(false)}
            onSelect={handleSelectToken}
            selectedToken={activeField === 'from' ? fromToken : toToken}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default Swap; 
import React from 'react';
import { FaDiscord, FaTwitter, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
  const links = [
    { title: 'Community', items: ['Discord', 'Twitter', 'Blog'] },
    { title: 'Resources', items: ['Documentation', 'GitHub', 'Analytics'] },
    { title: 'Protocol', items: ['About', 'Careers', 'Contact'] },
  ];

  return (
    <footer className="py-16 bg-gray-900/80 backdrop-blur-md border-t border-gray-700/50">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Logo and Social Links */}
          <div className="space-y-6">
            <Link to="/" className="inline-block">
              <span className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-emerald-500 bg-clip-text text-transparent">VORDEX</span>
            </Link>
            <p className="text-gray-400 text-sm">
              The next generation decentralized exchange protocol.
            </p>
            <div className="flex gap-4">
              <a 
                href="/" 
                className="w-10 h-10 rounded-xl bg-gray-800/50 flex items-center justify-center text-gray-400 hover:text-emerald-400 hover:bg-gray-700/50 transition-all duration-200"
              >
                <FaDiscord size={20} />
              </a>
              <a 
                href="/" 
                className="w-10 h-10 rounded-xl bg-gray-800/50 flex items-center justify-center text-gray-400 hover:text-emerald-400 hover:bg-gray-700/50 transition-all duration-200"
              >
                <FaTwitter size={20} />
              </a>
              <a 
                href="/" 
                className="w-10 h-10 rounded-xl bg-gray-800/50 flex items-center justify-center text-gray-400 hover:text-emerald-400 hover:bg-gray-700/50 transition-all duration-200"
              >
                <FaGithub size={20} />
              </a>
            </div>
          </div>

          {/* Links Sections */}
          {links.map((section) => (
            <div key={section.title}>
              <h3 className="text-white font-semibold mb-4">{section.title}</h3>
              <ul className="space-y-3">
                {section.items.map((item) => (
                  <li key={item}>
                    <a 
                      href="/" 
                      className="text-gray-400 hover:text-emerald-400 transition-colors duration-200"
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="mt-16 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-sm">
              © 2024 VORDEX. All rights reserved.
            </p>
            <div className="flex gap-6 text-sm">
              <a href="/" className="text-gray-400 hover:text-emerald-400 transition-colors">Terms</a>
              <a href="/" className="text-gray-400 hover:text-emerald-400 transition-colors">Privacy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 
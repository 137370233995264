import React, { useState } from 'react';
import { FaSearch, FaTimes, FaStar } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const TOKENS = [
  { symbol: 'BTC', name: 'Bitcoin', image: '/tokens-icons/btc.png', balance: 0.5, popular: true },
  { symbol: 'ETH', name: 'Ethereum', image: '/tokens-icons/eth.png', balance: 2.5, popular: true },
  { symbol: 'BNB', name: 'Binance Coin', image: '/tokens-icons/bnb.png', balance: 10.0, popular: true },
  { symbol: 'USDT', name: 'Tether', image: '/tokens-icons/usdt.png', balance: 5000.00, popular: true },
  { symbol: 'USDC', name: 'USD Coin', image: '/tokens-icons/usdc.png', balance: 5000.00, popular: true },
  { symbol: 'DAI', name: 'Dai', image: '/tokens-icons/dai.png', balance: 5000.00 },
  { symbol: 'VORDEX', name: 'Vordex', image: '/tokens-icons/btc.png', balance: 2500.00 },
];

function TokenSelector({ isOpen, onClose, onSelect, selectedToken }) {
  const [searchQuery, setSearchQuery] = useState('');
  const popularTokens = TOKENS.filter(token => token.popular);
  
  const filteredTokens = TOKENS.filter(token => 
    token.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
    token.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/80 backdrop-blur-sm z-40"
          />

          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-x-0 top-[20%] mx-auto max-w-md p-4 z-50"
          >
            <div className="bg-gray-800/90 backdrop-blur-md rounded-2xl shadow-xl border border-gray-700/50">
              {/* Header */}
              <div className="flex justify-between items-center p-6 border-b border-gray-700/50">
                <div>
                  <h2 className="text-xl font-bold text-white">Select Token</h2>
                  <p className="text-gray-400 text-sm mt-1">Search or select from the list</p>
                </div>
                <motion.button 
                  whileHover={{ scale: 1.1, rotate: 90 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={onClose}
                  className="p-2 hover:bg-gray-700/50 rounded-xl text-gray-400 hover:text-white transition-colors"
                >
                  <FaTimes size={20} />
                </motion.button>
              </div>

              {/* Search */}
              <div className="p-6">
                <div className="relative">
                  <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search by name or paste address"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full bg-gray-700/50 rounded-xl py-3 pl-11 pr-4 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-400/50 transition-all"
                  />
                </div>

                {/* Popular Tokens */}
                {!searchQuery && (
                  <div className="mt-4">
                    <div className="flex items-center gap-2 mb-3">
                      <FaStar className="text-emerald-400" size={14} />
                      <span className="text-sm text-gray-400">Popular tokens</span>
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {popularTokens.map(token => (
                        <motion.button
                          key={`popular-${token.symbol}`}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            onSelect(token);
                            onClose();
                          }}
                          className="flex items-center gap-2 bg-gray-700/50 hover:bg-gray-700 rounded-xl px-3 py-2 transition-colors"
                        >
                          <img src={token.image} alt={token.symbol} className="w-5 h-5 rounded-full" />
                          <span className="text-white font-medium">{token.symbol}</span>
                        </motion.button>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Token List with custom scrollbar */}
              <div 
                className="max-h-[300px] overflow-y-auto pr-2"
                style={{
                  '--scrollbar-width': '6px',
                  '--scrollbar-track-bg': 'transparent',
                  '--scrollbar-thumb-bg': 'rgba(16, 185, 129, 0.3)',
                  '--scrollbar-thumb-hover-bg': 'rgba(16, 185, 129, 0.5)',
                  '--scrollbar-thumb-active-bg': 'rgba(16, 185, 129, 0.7)',
                  scrollbarWidth: 'thin',
                  scrollbarColor: 'var(--scrollbar-thumb-bg) var(--scrollbar-track-bg)',
                  msOverflowStyle: 'none'
                }}
              >
                <style>
                  {`
                    div::-webkit-scrollbar {
                      width: var(--scrollbar-width);
                    }

                    div::-webkit-scrollbar-track {
                      background: var(--scrollbar-track-bg);
                      border-radius: 10px;
                    }

                    div::-webkit-scrollbar-thumb {
                      background: var(--scrollbar-thumb-bg);
                      border-radius: 10px;
                      border: 2px solid transparent;
                      background-clip: padding-box;
                    }

                    div::-webkit-scrollbar-thumb:hover {
                      background: var(--scrollbar-thumb-hover-bg);
                      background-clip: padding-box;
                    }

                    div::-webkit-scrollbar-thumb:active {
                      background: var(--scrollbar-thumb-active-bg);
                      background-clip: padding-box;
                    }
                  `}
                </style>
                <div className="space-y-1">
                  {filteredTokens.map(token => (
                    <motion.button
                      key={token.symbol}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      whileHover={{ backgroundColor: 'rgba(55, 65, 81, 0.5)' }}
                      onClick={() => {
                        onSelect(token);
                        onClose();
                      }}
                      className={`w-full flex items-center justify-between p-4 rounded-xl transition-all duration-200 ${
                        selectedToken?.symbol === token.symbol ? 'bg-gray-700/50' : ''
                      }`}
                    >
                      <div className="flex items-center gap-3">
                        <div className="relative">
                          <img 
                            src={token.image} 
                            alt={token.symbol} 
                            className="w-8 h-8 rounded-full ring-2 ring-gray-700/50" 
                          />
                          {token.popular && (
                            <div className="absolute -top-1 -right-1 w-3 h-3 bg-emerald-400 rounded-full" />
                          )}
                        </div>
                        <div className="text-left">
                          <div className="text-white font-semibold group-hover:text-emerald-400 transition-colors">
                            {token.symbol}
                          </div>
                          <div className="text-gray-400 text-sm">{token.name}</div>
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-white font-medium">{token.balance.toFixed(4)}</div>
                        <div className="text-gray-400 text-sm">Balance</div>
                      </div>
                    </motion.button>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export default TokenSelector; 
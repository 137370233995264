import React, { useState } from 'react';
import { FaPlus, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Enregistrez les composants Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LIQUIDITY_POOLS = [
  {
    id: 1,
    token0: { symbol: 'VORDEX', image: '/tokens-icons/btc.png' },
    token1: { symbol: 'DAI', image: '/tokens-icons/dai.png' },
    tvl: 2500000,
    apr: 125,
    volume24h: 150000,
    myLiquidity: 5000,
    myShare: 0.2
  },
  {
    id: 2,
    token0: { symbol: 'VORDEX', image: '/tokens-icons/btc.png' },
    token1: { symbol: 'USDT', image: '/tokens-icons/usdt.png' },
    tvl: 1800000,
    apr: 85,
    volume24h: 95000,
    myLiquidity: 0,
    myShare: 0
  },
  {
    id: 3,
    token0: { symbol: 'VORDEX', image: '/tokens-icons/btc.png' },
    token1: { symbol: 'USDC', image: '/tokens-icons/usdc.png' },
    tvl: 900000,
    apr: 95,
    volume24h: 75000,
    myLiquidity: 1200,
    myShare: 0.13
  }
];

// Rename RECENT_TRADES data
const RECENT_TRADES = [
  {
    id: 1,
    type: 'Swap',
    from: { amount: '1000', symbol: 'VORDEX' },
    to: { amount: '500', symbol: 'DAI' },
    time: '2 min ago',
    value: '$1,234'
  },
  {
    id: 2,
    type: 'Swap',
    from: { amount: '500', symbol: 'VORDEX' },
    to: { amount: '1200', symbol: 'USDC' },
    time: '5 min ago',
    value: '$1,456'
  },
  {
    id: 3,
    type: 'Swap',
    from: { amount: '2000', symbol: 'VORDEX' },
    to: { amount: '950', symbol: 'USDT' },
    time: '10 min ago',
    value: '$2,345'
  }
];

function Pool() {
  const [activeTab, setActiveTab] = useState('all');
  const [selectedPool, setSelectedPool] = useState(null);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.4,
        ease: "easeIn"
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return `$${(num / 1000000).toFixed(1)}M`;
    } else if (num >= 1000) {
      return `$${(num / 1000).toFixed(1)}K`;
    }
    return `$${num.toFixed(2)}`;
  };

  // Update chart data labels
  const chartData = {
    labels: ['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan'],
    datasets: [{
      label: 'Trading Volume',
      data: [65000, 59000, 80000, 81000, 56000, 75000, 90000],
      fill: false,
      borderColor: '#34d399',
      tension: 0.1
    }]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#fff'
        }
      }
    },
    scales: {
      y: {
        ticks: { color: '#fff' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      },
      x: {
        ticks: { color: '#fff' },
        grid: { color: 'rgba(255, 255, 255, 0.1)' }
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 flex flex-col">
      <Navbar />
      
      <motion.div 
        className="flex-grow container mx-auto px-6 pt-24 pb-12 max-w-7xl"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Stats Overview Section */}
        <motion.div 
          variants={itemVariants}
          className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10"
        >
          <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
            <div className="text-gray-400 text-sm mb-2">Total Value Locked</div>
            <div className="text-2xl font-bold text-white">$5.2M</div>
            <div className="text-emerald-400 text-sm mt-1">↑ 12.5%</div>
          </div>
          <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
            <div className="text-gray-400 text-sm mb-2">24h Trading Volume</div>
            <div className="text-2xl font-bold text-white">$320K</div>
            <div className="text-emerald-400 text-sm mt-1">↑ 8.3%</div>
          </div>
          <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl p-6 border border-gray-700/50">
            <div className="text-gray-400 text-sm mb-2">Total Pools</div>
            <div className="text-2xl font-bold text-white">12</div>
            <div className="text-emerald-400 text-sm mt-1">+2 this week</div>
          </div>
        </motion.div>

        {/* Main Content */}
        <div className="bg-gray-800/70 backdrop-blur-sm rounded-2xl border border-gray-700/50 p-8">
          {/* Header */}
          <motion.div 
            className="flex justify-between items-center mb-10"
            variants={itemVariants}
          >
            <h1 className="text-3xl font-bold text-white tracking-tight">Liquidity Pools</h1>
            <Link 
              to="/pool/add" 
              className="flex items-center gap-2 bg-gradient-to-r from-emerald-400 to-emerald-500 text-gray-900 font-bold py-3 px-6 rounded-xl hover:shadow-lg hover:shadow-emerald-500/20 transition-all duration-200"
            >
              <FaPlus />
              Add Liquidity
            </Link>
          </motion.div>

          {/* Tabs */}
          <motion.div 
            className="flex gap-4 mb-8 bg-gray-700/50 p-1.5 rounded-2xl w-fit"
            variants={itemVariants}
          >
            <button
              onClick={() => setActiveTab('all')}
              className={`px-6 py-2.5 rounded-xl font-semibold transition-all duration-300 ${
                activeTab === 'all'
                  ? 'bg-emerald-400 text-gray-900 shadow-lg'
                  : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
              }`}
            >
              All Pools
            </button>
            <button
              onClick={() => setActiveTab('my')}
              className={`px-6 py-2.5 rounded-xl font-semibold transition-all duration-300 ${
                activeTab === 'my'
                  ? 'bg-emerald-400 text-gray-900 shadow-lg'
                  : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
              }`}
            >
              My Pools
            </button>
          </motion.div>

          {/* Pool Cards */}
          <AnimatePresence mode="wait">
            <motion.div 
              key={activeTab}
              className="grid gap-5"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4 }}
            >
              {LIQUIDITY_POOLS
                .filter(pool => activeTab === 'all' || pool.myLiquidity > 0)
                .map((pool) => (
                  <motion.div
                    key={pool.id}
                    variants={itemVariants}
                    className="bg-gray-700/50 backdrop-blur-sm rounded-xl p-6 hover:bg-gray-700/70 transition-all duration-300 border border-gray-600/50 hover:border-emerald-500/30"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className="flex items-center -space-x-2">
                          <img src={pool.token0.image} alt={pool.token0.symbol} className="w-10 h-10 rounded-full ring-2 ring-gray-800" />
                          <img src={pool.token1.image} alt={pool.token1.symbol} className="w-10 h-10 rounded-full ring-2 ring-gray-800" />
                        </div>
                        <div>
                          <span className="text-white font-semibold text-lg">
                            {pool.token0.symbol}/{pool.token1.symbol}
                          </span>
                          <div className="text-emerald-400 text-sm">Active</div>
                        </div>
                      </div>

                      <div className="flex items-center gap-8">
                        <div className="text-right">
                          <div className="text-gray-400 text-sm">TVL</div>
                          <div className="text-white font-semibold text-lg">{formatNumber(pool.tvl)}</div>
                        </div>
                        <div className="text-right">
                          <div className="text-gray-400 text-sm">APR</div>
                          <div className="text-emerald-400 font-semibold text-lg">{pool.apr}%</div>
                        </div>
                        <div className="text-right">
                          <div className="text-gray-400 text-sm">24h Volume</div>
                          <div className="text-white font-semibold text-lg">{formatNumber(pool.volume24h)}</div>
                        </div>
                        {pool.myLiquidity > 0 && (
                          <div className="text-right">
                            <div className="text-gray-400 text-sm">My Share</div>
                            <div className="text-white font-semibold text-lg">{pool.myShare.toFixed(2)}%</div>
                          </div>
                        )}
                        <button 
                          onClick={() => setSelectedPool(pool)}
                          className="bg-gray-600/50 hover:bg-gray-600 p-3 rounded-xl transition-colors duration-200"
                        >
                          <FaChartLine className="text-emerald-400" />
                        </button>
                      </div>
                    </div>
                  </motion.div>
                ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </motion.div>

      {/* Modal avec un design plus moderne */}
      {selectedPool && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-6">
          <motion.div 
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-gray-800/90 backdrop-blur-md rounded-2xl p-8 max-w-6xl w-full mx-4 border border-gray-700/50 shadow-2xl"
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-white">
                {selectedPool.token0.symbol}/{selectedPool.token1.symbol} - Pool Activity
              </h2>
              <button 
                onClick={() => setSelectedPool(null)}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>
            
            <div className="flex gap-6">
              {/* Chart */}
              <div className="flex-1">
                <h3 className="text-white font-semibold mb-4">Trading Volume</h3>
                <div className="h-[400px]">
                  <Line data={chartData} options={chartOptions} />
                </div>
              </div>

              {/* Recent trades list */}
              <div className="w-80">
                <h3 className="text-white font-semibold mb-4">Recent Trades</h3>
                <div className="space-y-3 max-h-[400px] overflow-y-auto pr-2">
                  {RECENT_TRADES.map(trade => (
                    <div 
                      key={trade.id}
                      className="bg-gray-700 rounded-lg p-3 hover:bg-gray-650 transition-colors"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-emerald-400 font-medium">{trade.type}</span>
                        <span className="text-gray-400 text-sm">{trade.time}</span>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        <span className="text-white">{trade.from.amount}</span>
                        <span className="text-gray-400">{trade.from.symbol}</span>
                        <span className="text-gray-400">→</span>
                        <span className="text-white">{trade.to.amount}</span>
                        <span className="text-gray-400">{trade.to.symbol}</span>
                      </div>
                      <div className="text-right text-sm text-gray-400">
                        {trade.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Pool; 